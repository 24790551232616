import React, { useEffect, useState } from "react";
import Main from "../../layout/main/Main";
import { Select, MenuItem, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import ProductDetails from "./components/ProductDetails";
import VersionDetails from "./components/VersionDetails";
import ProjectDetails from "./components/ProjectDetails";
import RelatedProductsList from "./components/RelatedProductsList";
import ProductTimesComponent from "./components/ProductTimes";
import RelatedProductsDetails from "./components/RelatedProductsDetails";
import { getAllCollections, getAllTemplates } from "./utils/fetchFunctions";
import { Product_group, ProductVersion, ProductTimes, ProductGroupFieldConfig, ProductVersionFieldConfig, ProductTimesFieldConfig } from "./types/ProductInterface";
import { Project, ProjectBdttabel, ProjectProduct, ProjectFieldConfig, ProjectProductFieldConfig,ProjectBdttabelFieldConfig } from "./types/ProjectInterface";
import BdtTabel from "./components/Bedrijfterein";
import useCustomClaims from "../../hooks/useCustomClaims";
import UnauthorizedPage from "../unauthorized";



const PortfolioPage: React.FC = () => {
    const { t } = useTranslation();
    const [selection, setSelection] = useState<string>("project");
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);
    const [allProjects, setAllProjects] = useState<Project[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product_group | null>(null);
    const [allProductGroup, setAllProductGroup] = useState<Product_group[]>([]);
    const [allProductVersions, setAllProductVersions] = useState<ProductVersion[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<ProductVersion | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [allProductTimes, setAllProductTimes] = useState<ProductTimes[]>([]);
    const [allProjectsProducts, setAllProjectsProducts] = useState<ProjectProduct[]>([]);
    const [selectedProjectProduct, setSelectedProjectProduct] = useState<ProjectProduct | null>(null);
    const [allProjectsBdt, setAllProjectsBdt] = useState<ProjectBdttabel[]>([]);
    const [projectFieldConfigs, setProjectFieldConfigs] = useState<ProjectFieldConfig[]>([]);
    const [projectProductFieldConfigs, setProjectProductFieldConfigs] = useState<ProjectProductFieldConfig[]>([]);
    const [productGroupFieldConfigs, setproductGroupFieldConfigs] = useState<ProductGroupFieldConfig[]>([]);
    const [productVersionFieldConfigs, setProductVersionFieldConfigs] = useState<ProductVersionFieldConfig[]>([]);
    const [productTimesFieldConfigs, setProductTimesFieldConfigs] = useState<ProductTimesFieldConfig[]>([]);
    const [projectBdttabelFieldConfigs, setProjectBdttabelFieldConfigs] = useState<ProjectBdttabelFieldConfig[]>([]);
    const customClaims = useCustomClaims()

    // Fetch data
    useEffect(() => {
        getAllCollections("project_information").then(setAllProjects);
        getAllCollections("product_group").then(setAllProductGroup);
        getAllCollections("product_version").then(setAllProductVersions);
        getAllCollections("product_times").then(setAllProductTimes);
        getAllCollections("project_product_information").then(setAllProjectsProducts);
        getAllCollections("project_bdt_fases").then(setAllProjectsBdt);
    }, []);

    // useEffect(() => {console.log(templateProject_Information)  }, [templateProject_Information]);
    useEffect(() => {
        if (projectFieldConfigs.length === 0){
            const tempData = getProjectInterface("project_information")
            
            tempData.then((data) => {
                setProjectFieldConfigs(data)
            })
        }
        if (projectProductFieldConfigs.length === 0){
            const tempData = getProjectInterface("project_product_information")
            console.log("template related", tempData)
            tempData.then((data) => {
                setProjectProductFieldConfigs(data)
            })
        }
        if (projectBdttabelFieldConfigs.length === 0){
            const tempData = getProjectInterface("project_bdt_fases")
            console.log("template related", tempData)
            tempData.then((data) => {
                setProjectBdttabelFieldConfigs(data)
            })
        }
        if (productGroupFieldConfigs.length === 0){
            const tempData = getProjectInterface("product_group")
            console.log("template related", tempData)
            tempData.then((data) => {
                setproductGroupFieldConfigs(data)
            })
        }
        if (productVersionFieldConfigs.length === 0){
            const tempData = getProjectInterface("product_version")
            console.log("template related", tempData)
            tempData.then((data) => {
                setProductVersionFieldConfigs(data)
            })
        }
        if (productTimesFieldConfigs.length === 0){
            const tempData = getProjectInterface("product_times")
            console.log("template related", tempData)
            tempData.then((data) => {
                setProductTimesFieldConfigs(data)
            })
        }
        

    }, []);

    const getProjectInterface = async (collection: string) => {
        const templateData = await getAllTemplates(collection)

        const data = templateData.map((field: any) => ({
            key: field.id,
            label: field.name || field.id, // Fallback to id if name is missing
            type: mapDataTypeToType(field.data_type),
            readonly: !field.visible,
            mandatory: field.mandatory || false,
            order_number: field.order_number || null // Optional: Add this if mandatory exists
            }),
        );

        return data
    }

    const mapDataTypeToType = (dataType: string): "text" | "number" | "date" | "boolean" => {
        switch (dataType) {
          case "STRING":
            return "text";
          case "BOOLEAN":
            return "boolean";
          case "INTEGER":
            return "number";
          case "DATE":
            return "date";
          default:
            return "text"; // Default type
        }
      };
      
    const handleProjectSelection = (project: Project) => {
        setSelectedProject(project);
        setSelectedProjectProduct(null); // Reset related product when a new project is selected
    };

    const filteredProjects = allProjects.filter(
        (project) =>
            project.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.project_number?.toString().includes(searchQuery)
    );

    const filteredProductGroups = allProductGroup.filter(
        (productGroup) =>
            productGroup.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            productGroup.product_number?.toString().includes(searchQuery)
    );

    return (customClaims &&
		    (customClaims?.["admin"] ||
			customClaims?.["portfolio-manager"]) ? (
        <Main>
            {/* Page Header */}
            <div
                className="quote-style-border"
                style={{
                    backgroundColor: "#DA8A67",
                }}
            >
                <h2 className="quote-style-h2">{t("PortfolioQuote")}</h2>
            </div>

            {/* Main Layout */}
            <div style={{ display: "flex", height: "100vh" }}>
                {/* Sidebar */}
                <div style={{ width: "200px", backgroundColor: "#f5f5f5", padding: "20px" }}>
                    <Select
                        value={selection}
                        onChange={(e) => setSelection(e.target.value as string)}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="project">Project Portfolio</MenuItem>
                        <MenuItem value="product">Product Portfolio</MenuItem>
                    </Select>
                    <TextField
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                        {selection === "project" ? (
                            filteredProjects
                            .sort((a, b) => b.project_number - a.project_number)
                            .map((project) => (
                                <div
                                    key={project.id}
                                    onClick={() => handleProjectSelection(project)}
                                    style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                        backgroundColor: selectedProject?.id === project.id ? "#ddd" : "#fff",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    {project.project_number} - {project.name}
                                </div>
                            ))
                        ) : (
                            filteredProductGroups.map((group) => (
                                <div
                                    key={group.id}
                                    onClick={() => setSelectedProduct(group)}
                                    style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                        backgroundColor: selectedProduct?.id === group.id ? "#ddd" : "#fff",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    {group.product_number} - {group.product_name}
                                </div>
                            ))
                        )}
                    </div>
                </div>

                {/* Content Section */}
                <div style={{ flex: 1, padding: "20px" , maxWidth: "85vw" ,maxHeight: "100vw" }}>
                    {/* Collapsible Sections */}
                    {selection === "project" && selectedProject && (
                        <>
                            {/* Project Details */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ fontWeight: "bold" }}>Project Details</Typography>


                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProjectDetails
                                        projectFieldConfigs = {projectFieldConfigs}
                                        project={selectedProject}
                                        setSelectedProject={setSelectedProject}
                                        allProjects={allProjects}
                                        setAllProjects={setAllProjects}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Bdt Tabel */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ fontWeight: "bold" }} >Bdt Tabel</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <BdtTabel
                                    projectBdttabelFieldConfigs={projectBdttabelFieldConfigs}
                                    allProjectsBdt={allProjectsBdt}
                                    setAllProjectsBdt={setAllProjectsBdt}
                                    selectedProject={selectedProject} // Pass the selected project
                                    selectedProjectBdt={null}
                                    onSelectProjectBdt={() => {}}
                                />


                                </AccordionDetails>
                            </Accordion>


                            {/* Related Products */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ fontWeight: "bold" }}>Related Products</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RelatedProductsList
                                        project={selectedProject}
                                        allProjectsProducts={allProjectsProducts}
                                        onSelectProjectProduct={setSelectedProjectProduct}
                                        selectedProjectProduct={selectedProjectProduct}
                                        setAllProjectsProducts={setAllProjectsProducts}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Related Products Details */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ fontWeight: "bold" }}>Related Product Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <RelatedProductsDetails
                                        projectProductFieldConfigs ={projectProductFieldConfigs}
                                        selectedProject={selectedProject?.project_number || 0}
                                        selectedProjectProduct={selectedProjectProduct}
                                        setAllProjectsProducts={setAllProjectsProducts}
                                        allProjectsProducts={allProjectsProducts}
                                        onSelectProjectProduct={setSelectedProjectProduct}
                                        projectPrefix={selectedProject?.prefix || ""}
                                        projectnumber={selectedProject?.project_number}
                                        allProductGroups={allProductGroup}
                                        allProductVersions={allProductVersions}
                                        allProductTimes={allProductTimes}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}

                    {selection === "product" && selectedProduct && (
                        <>
                            {/* Product Details */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ fontWeight: "bold" }}>Product Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProductDetails
                                        productGroupFieldConfigs={productGroupFieldConfigs}
                                        selectedProduct={selectedProduct}
                                        setSelectedProduct={setSelectedProduct}
                                        allProductGroup={allProductGroup}
                                        setAllProductGroup={setAllProductGroup}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Versions */}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ fontWeight: "bold" }}>Product Versions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <VersionDetails
                                        productVersionFieldConfigs={productVersionFieldConfigs}
                                        allProductVersions={allProductVersions}
                                        selectedProduct={selectedProduct}
                                        selectedVersion={selectedVersion}
                                        setSelectedVersion={setSelectedVersion}
                                        setProductVersions={setAllProductVersions}
                                        productVersions={allProductVersions}
                                        allProductTimes={allProductTimes}
                                    />
                                </AccordionDetails>
                            </Accordion>

                            {/* Product Times */}
                            {selectedVersion && (
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography sx={{ fontWeight: "bold" }}>Product Times</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ProductTimesComponent
                                            productTimesFieldConfigs={productTimesFieldConfigs}
                                            allProductTimes={allProductTimes}
                                            selectedVersion={selectedVersion}
                                            setAllProductTimes={setAllProductTimes}
                                            selectedProduct={selectedProduct}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Main>
        ): (
            <UnauthorizedPage />
        )
    );
};

export default PortfolioPage;
