import React, {useEffect, useState} from "react";
import {List, ListItem, ListItemIcon, Tooltip, Divider} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import PortfolioIcon from "@mui/icons-material/Code";
import SolarPanelIcon from "@mui/icons-material/SolarPower";
import EnergyIcon from "@mui/icons-material/FlashOn";
import ListAltIcon from "@mui/icons-material/ListAlt";
import RentalIcon from "@mui/icons-material/Weekend";
import DataIcon from "@mui/icons-material/Storage";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import {useLocation, useNavigate} from "react-router-dom";
import {signOut} from "firebase/auth";
import {auth} from "../../../config/firebase";
import LanguageSwitcher from "../../language-switcher";
import PersonIcon from "@mui/icons-material/Person";
import {useTranslation} from "react-i18next";
import AddTaskIcon from "@mui/icons-material/AddTask";
import useCustomClaims from "../../../hooks/useCustomClaims";
import DashboardIcon from '@mui/icons-material/BarChart';
import BalconyIcon from '@mui/icons-material/Balcony';


const Sidebar: React.FC = () => {
	const location = useLocation();
	location["pathname"] = "/" + location.pathname.split("/")[1];
	const customClaims = useCustomClaims();

	const navigate = useNavigate();
	const {t} = useTranslation(); // Hook to access translation

	const handleNavigation = (path: string) => {
		navigate(path);
	};

	const handleLogout = async () => {
		await signOut(auth);
		navigate("/login");
	};

	return (
		<List
			component="nav"
			sx={{width: "100%", maxWidth: 60, minHeight: "100vh", bgcolor: "#B2C2B6"}}
		>
			<LanguageSwitcher />
			<Tooltip title={t("home")} placement="right">
				<ListItem button onClick={() => handleNavigation("/home")}>
					<ListItemIcon>
						<HomeIcon
							sx={{
								fontSize: 30,
								color: location.pathname === "/home" ? "#4CAF50" : "white",
							}}
						/>
					</ListItemIcon>
				</ListItem>
			</Tooltip>

			<Tooltip title={t("info")} placement="right">
				<ListItem button onClick={() => handleNavigation("/info")}>
					<ListItemIcon>
						<InfoIcon
							sx={{
								fontSize: 30,
								color: location.pathname === "/info" ? "#4CAF50" : "white",
							}}
						/>
					</ListItemIcon>
				</ListItem>
			</Tooltip>

			{customClaims &&
			<Tooltip title={t("Dashboards")} placement="right">
				<ListItem button onClick={() => handleNavigation("/Dashboarding")}>
					<ListItemIcon>
						<DashboardIcon
							sx={{
								fontSize: 30,
								color: location.pathname === "/Dashboarding" ? "#4CAF50" : "white",
							}}
						/>
					</ListItemIcon>
				</ListItem>
			</Tooltip>}

			{customClaims &&
				(customClaims?.["praeter-advisor"] || customClaims?.["admin"] || customClaims?.["sales-manager"]) && (
					<Tooltip title={t("customerSuccessTitle")} placement="right">
						<ListItem
							button
							onClick={() => handleNavigation("/customer-success")}
						>
							<ListItemIcon>
								<PersonIcon
									sx={{
										fontSize: 30,
										color:
											location.pathname === "/customer-success"
												? "#4CAF50"
												: "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}

			<Divider />
			{customClaims &&
				(customClaims?.["praeter-advisor"] ||
					customClaims?.["admin"] ||
					customClaims?.["solar-energy-advisor"]) && (
					<Tooltip title={t("solarEnergy")} placement="right">
						<ListItem button onClick={() => handleNavigation("/solar-energy")}>
							<ListItemIcon>
								<SolarPanelIcon
									sx={{
										fontSize: 30,
										color:
											location.pathname === "/solar-energy"
												? "#4CAF50"
												: "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}
			{customClaims &&
				(customClaims?.["praeter-advisor"] ||
					customClaims?.["admin"] ||
					customClaims?.["solar-energy-advisor"]) && (
					<Tooltip title={t("solarRental")} placement="right">
						<ListItem button onClick={() => handleNavigation("/solar-rental")}>
							<ListItemIcon>
								<RentalIcon
									sx={{
										fontSize: 30,
										color:
											location.pathname === "/solar-rental"
												? "#4CAF50"
												: "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}

			{customClaims &&
				(customClaims?.["praeter-advisor"] ||
					customClaims?.["admin"] ||
					customClaims?.["energy-screening-advisor"]) && (
					<Tooltip title={t("energyScreening")} placement="right">
						<ListItem
							button
							onClick={() => handleNavigation("/energy-screening")}
						>
							<ListItemIcon>
								<ListAltIcon
									sx={{
										fontSize: 30,
										color:
											location.pathname === "/energy-screening"
												? "#4CAF50"
												: "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}
			{customClaims &&
				(customClaims?.["praeter-advisor"] ||
					customClaims?.["admin"] ||
					customClaims?.["energy-saving-advisor"]) && (
					<Tooltip title={t("energySaving")} placement="right">
						<ListItem button onClick={() => handleNavigation("/energy-saving")}>
							<ListItemIcon>
								<EnergyIcon
									sx={{
										fontSize: 30,
										color:
											location.pathname === "/energy-saving"
												? "#4CAF50"
												: "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}
			{customClaims && (customClaims?.["praeter-advisor"] ||
					customClaims?.["admin"] ||
					customClaims?.["energy-saving-advisor"]) && (
				<Tooltip title={t("elabeltitle")} placement="right">
					<ListItem button onClick={() => handleNavigation("/e-label-bv")}>
						<ListItemIcon>
							<AddTaskIcon
								sx={{
									fontSize: 30,
									color:
										location.pathname === "/e-label-bv" ? "#4CAF50" : "white",
								}}
							/>
						</ListItemIcon>
					</ListItem>
				</Tooltip>
			)}
			<Divider />

			{customClaims &&
				(customClaims?.["portfolio-manager"] || customClaims?.["admin"]) && (
			<Tooltip title={t("portfolio")} placement="right">
				<ListItem button onClick={() => handleNavigation("/portfolio")}>
					<ListItemIcon>
						<BalconyIcon
							sx={{
								fontSize: 30,
								color: location.pathname === "/info" ? "#4CAF50" : "white",
							}}
						/>
					</ListItemIcon>
				</ListItem>
			</Tooltip>
			)}

			
			
			{customClaims &&
				(customClaims?.["data-manager"] || customClaims?.["admin"]) && (
					<Tooltip title={t("data")} placement="right">
						<ListItem button onClick={() => handleNavigation("/data")}>
							<ListItemIcon>
								<DataIcon
									sx={{
										fontSize: 30,
										color: location.pathname === "/data" ? "#4CAF50" : "white",
									}}
								/>
							</ListItemIcon>
						</ListItem>
					</Tooltip>
				)}

			{customClaims && customClaims?.["admin"] && (
				<Tooltip title={t("settings")}>
					<ListItem button onClick={() => handleNavigation("/settings")}>
						<ListItemIcon>
							<SettingsIcon
								sx={{
									fontSize: 30,
									color:
										location.pathname === "/settings" ? "#4CAF50" : "white",
								}}
							/>
						</ListItemIcon>
					</ListItem>
				</Tooltip>
			)}
			
			<Divider />

			<Tooltip title={t("logout")} placement="right">
				<ListItem button onClick={handleLogout}>
					<ListItemIcon>
						<LogoutIcon
							sx={{
								fontSize: 30,
								color: location.pathname === "/logout" ? "#4CAF50" : "white",
							}}
						/>
					</ListItemIcon>
				</ListItem>
			</Tooltip>
		</List>
	);
};

export default Sidebar;
