import React, { useState } from "react";
import { auth } from "../../../config/firebase";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
} from "@mui/material";
import { ProjectBdttabel, Project , ProjectBdttabelFieldConfig} from "../types/ProjectInterface";
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions";
import {formatDate} from "../config/tarifConfig"
import {postPortfolioData} from "../../../services/apiFunctions"


interface BdtTabelProps {
    allProjectsBdt: ProjectBdttabel[];
    setAllProjectsBdt: (products: ProjectBdttabel[]) => void;
    selectedProject: Project | null;
    selectedProjectBdt: ProjectBdttabel | null;
    onSelectProjectBdt: (product: ProjectBdttabel | null) => void;
    projectBdttabelFieldConfigs: ProjectBdttabelFieldConfig[]
}

const BdtTabel: React.FC<BdtTabelProps> = ({
    allProjectsBdt,
    setAllProjectsBdt,
    selectedProject,
    selectedProjectBdt,
    onSelectProjectBdt,
    projectBdttabelFieldConfigs,
}) => {
    const [editingRowId, setEditingRowId] = useState<string | null>(null);
    const [editedRow, setEditedRow] = useState<ProjectBdttabel | null>(null);
    const [newRow, setNewRow] = useState<ProjectBdttabel | null>(null);

    // Filter out readonly fields
    const editableFields = projectBdttabelFieldConfigs.filter((config) => !config.readonly);
    const sortedEditableFields = editableFields.sort((a, b) => a.order_number - b.order_number);

    const filteredBdtRows = allProjectsBdt.filter(
        (row) => row.project_number === selectedProject?.project_number && row.prefix === selectedProject?.prefix
    );

    // Calculate totals for `hour_erp`, `hour_ers`, `price_3e`, and `hour_total`
    const erpTarrif = selectedProject?.erp_tarrif || 0; // Default to 0 if undefined
    const ersTarrif = selectedProject?.ers_tarrif || 0;
    const salesTarif = selectedProject?.sales_tarrif || 0;

    const totals = {
        hour_erp: filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_erp) || 0), 0),
        hour_ers: filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_ers) || 0), 0),
        price_3e: filteredBdtRows.reduce((sum, row) => sum + (Number(row.price_3e) || 0), 0),
        hour_total: filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_total) || 0), 0),
        price_erp: filteredBdtRows.reduce(
            (sum, row) => sum + (Number(row.hour_erp) || 0) * erpTarrif,
            0
        ),
        price_ers: filteredBdtRows.reduce(
            (sum, row) => sum + (Number(row.hour_ers) || 0) * ersTarrif,
            0
        ), 
        price_total: filteredBdtRows.reduce(
            (sum, row) => sum + (Number(row.hour_total) || 0) * salesTarif,
            0
        )
    };


    const handleEditClick = (row: ProjectBdttabel) => {
        setEditingRowId(row.id);
        setEditedRow({ ...row });
    };

    const handleSaveClick = async () => {
        if (editedRow) {
            console.log("Edited Row. id", editedRow.id)
            try {
                // Add `modified_on` and `modified_by` fields
                const updatedRow = {
                    ...editedRow,
                    modified_on: formatDate(new Date()), // Current date and time
                    modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
                };
                const apiPayload = {...updatedRow, rowkey: updatedRow.id, collection: "project_bdt_fases", action: "updated"};
    
                // Save the updated row to Firestore
                await mergeDoc("project_bdt_fases", updatedRow.id, updatedRow);
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
    
                // Update the local state with the saved row
                const updatedBdt = allProjectsBdt.map((product) =>
                    product.id === updatedRow.id ? updatedRow : product
                );
                setAllProjectsBdt(updatedBdt);
    
                // Exit edit mode and clear the edit state
                setEditingRowId(null);
                setEditedRow(null);
            } catch (error) {
                console.error("Failed to save changes:", error);
            }
        }
    };
    

    const handleCancelClick = () => {
        setEditingRowId(null);
        setEditedRow(null);
    };

    const handleDeleteClick = async (id: string) => {
        try {
            const confirmDelete = window.confirm("Are you sure you want to delete this item?");
            if (confirmDelete) {
                await removeDoc("project_bdt_fases", id);
                    
                const apiPayload = { rowkey: id, collection: "project_bdt_fases", action: "deleted", data: "test" };
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
    
                const updatedBdt = allProjectsBdt.filter((product) => product.id !== id);
                setAllProjectsBdt(updatedBdt);
                onSelectProjectBdt(null);
            }
        } catch (error) {
            console.error("Failed to delete row:", error);
        }
    };
    

    const handleAddNewRow = async () => {
        if (!newRow || !selectedProject) {
            console.error("Cannot add row: Ensure a project is selected and all required fields are filled.");
            return;
        }
    
        // Generate a new ID
        const newFaseNumber = newRow.fase_number || 0;
        const newId = `${selectedProject.prefix}_${selectedProject.project_number}.${newFaseNumber}`;
        console.log(`Adding new row with ID: ${newId}`);
    
        // Get the current user
        const currentUser = auth?.currentUser?.displayName || "Unknown User";
    
        // Create a new row object
        const rowToAdd: Omit<ProjectBdttabel, "id"> = {
            ...newRow,
            prefix: selectedProject.prefix,
            owner: currentUser,
            modified_by: currentUser,
            modified_on: formatDate(new Date()),
            created_on: formatDate(new Date()),
            project_number: selectedProject.project_number,
            
        };
    
        const apiPayload = {
            ...rowToAdd,
            rowkey: newId,
            collection: "project_bdt_fases",
            action: "created",
        };
    
        try {
            // Save the new row to the database
            await createDoc("project_bdt_fases", rowToAdd, newId);

            //API
            const apiResponse = await postPortfolioData(apiPayload);
            console.log("API Response:", apiResponse);
    
            // Add the new row to the state
            const newRowWithId = { ...rowToAdd, id: newId };
            setAllProjectsBdt([...allProjectsBdt, newRowWithId]);
            setNewRow(null);
    
            console.log("New row added successfully:", newRowWithId);
        } catch (error) {
            console.error("Failed to add new row:", error);
        }
    };
    

    const handleInputChange = (field: keyof ProjectBdttabel, value: string | number) => {
        if (editedRow) {
            setEditedRow({ ...editedRow, [field]: value });
        }
    };

    const handleNewRowInputChange = (field: keyof ProjectBdttabel, value: string | number) => {
        setNewRow((prev) => ({ ...prev, [field]: value } as ProjectBdttabel));
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                        {sortedEditableFields.map((config) => (
                            <TableCell key={config.key}>{config.label}</TableCell>
                        ))}
                        <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {/* Render rows */}
                        {filteredBdtRows.map((row) => (
                            <TableRow
                                key={row.id}
                                style={{
                                    backgroundColor:
                                        selectedProjectBdt?.id === row.id ? "#f0f8ff" : "inherit",
                                }}
                                onClick={() => onSelectProjectBdt(row)}
                            >
                                {editableFields.map((config) => (
                                    <TableCell key={config.key}>
                                        {editingRowId === row.id && !config.readonly ? (
                                            <TextField
                                                value={(editedRow as any)[config.key] || ""}
                                                onChange={(e) =>
                                                    handleInputChange(config.key, e.target.value)
                                                }
                                                type={config.type}
                                            />
                                        ) : (
                                            row[config.key as keyof ProjectBdttabel] || "N/A"
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell>
                                    {editingRowId === row.id ? (
                                        <>
                                            <Button onClick={handleSaveClick} color="primary">
                                                Save
                                            </Button>
                                            <Button onClick={handleCancelClick} color="secondary">
                                                Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button onClick={() => handleEditClick(row)} color="primary">
                                                Edit
                                            </Button>
                                            <Button onClick={() => handleDeleteClick(row.id)} color="error">
                                                Delete
                                            </Button>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Totals row */}
                        <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
                        {editableFields.map((config) => (
                            <TableCell key={config.key}>
                                {config.key === "hour_erp"
                                    ? totals.hour_erp
                                    : config.key === "hour_ers"
                                    ? totals.hour_ers
                                    : config.key === "hour_total"
                                    ? totals.hour_total
                                    : config.key === "description" // Add text to the description column
                                    ? "Total uren"
                                    : ""}
                            </TableCell>
                        ))}
                        {/* Empty cell for Actions column */}
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
                        {editableFields.map((config) => (
                            <TableCell key={config.key}>
                                {config.key === "hour_erp"
                                    ? `€ ${totals.price_erp.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                                    : config.key === "hour_ers"
                                    ? `€ ${totals.price_ers.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                                    : config.key === "hour_total"
                                    ? `€ ${totals.price_total.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                                    : config.key === "price_3e"
                                    ? `€ ${totals.price_3e.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                                    
                                    : config.key === "description" // Add text to the description column
                                    ? "Total (€)"
                                    : ""}
                            </TableCell>
                        ))}
                        {/* Empty cell for Actions column */}
                        <TableCell></TableCell>
                    </TableRow>






                        {/* New row input */}
                        <TableRow>
                            {editableFields.map((config) => (
                                <TableCell key={config.key}>
                                    <TextField
                                        placeholder={config.label}
                                        value={(newRow as any)?.[config.key] || ""}
                                        onChange={(e) =>
                                            handleNewRowInputChange(config.key, e.target.value)
                                        }
                                        type={config.type}
                                    />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Button
                                    onClick={handleAddNewRow}
                                    color="primary"
                                    disabled={!newRow || !newRow.fase_number || !newRow.description}
                                >
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default BdtTabel;
